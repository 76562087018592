
import { defineComponent, onMounted, ref } from 'vue';
import {
    getAllYears,
    getVersionByYear,
    getListByVersionAndYear,
    downloadTemplate,
    uploadRestrictCity,
} from '@/API/localRegistration';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { downloadFromStream } from '@/utils/payment/downloadFile';
import Upload from '@/views/LocalRegistration/components/Upload.vue';
import isExcel from '@/utils/closing/isExcel';
import { message } from 'ant-design-vue';
import YearPicker from '@/views/Payment/components/YearPicker.vue';
export default defineComponent({
    components: {
        Upload,
        YearPicker,
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const showUploadDrawer = ref<boolean>(false);
        const yearList = ref<any[]>([]);
        const year = ref<string | undefined>(undefined);
        const versionList = ref<any[]>([]);
        const version = ref<string | undefined>(undefined);

        const uploadYear = ref<string>('2022');
        const columns = [
            {
                title: 'Dealer Code',
                dataIndex: 'dealerCode',
                key: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerName',
                key: 'dealerName',
            },
            {
                title: '限牌城市曲线选择',
                dataIndex: 'restrictedCity',
                key: 'restrictedCity',
            },
            {
                title: 'Year',
                dataIndex: 'year',
                key: 'year',
            },
            {
                title: '上传时间',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
            },
            {
                title: '上传文件名称',
                dataIndex: 'version',
                key: 'version',
            },
        ];
        const data = ref<any[]>([]);

        const handleUpload = async (
            file: File,
            resolve: (value: unknown) => void,
            reject: (reason?: unknown) => void,
            options: any
        ) => {
            const _formData = new FormData();
            const fileName: string = file.name?.split('.')[0];
            _formData.append('file', file);
            try {
                const fileIsExcel = await isExcel(file);
                if (!fileIsExcel) {
                    message.error('file type error');
                    throw 'file type error';
                }
                const data = await uploadRestrictCity(
                    _formData,
                    uploadYear.value,
                    fileName,
                    {
                        onUploadProgress: (progressEvent: any) => {
                            if (progressEvent.lengthComputable) {
                                const percent =
                                    ((progressEvent.loaded /
                                        progressEvent.total) *
                                        100) |
                                    0;
                                options.percent.value = percent;
                            }
                        },
                    }
                );
                if (data.length === 0) {
                    return message.success('Upload Success.');
                } else {
                    store.commit(
                        'localRegistration/updateCurveSelectionUploadError',
                        data
                    );
                    router.push({
                        name: 'Curve Selection Upload Failed Reason',
                    });
                }
                // if (response.data.type === 'application/json') {
                //     const result = JSON.parse(await response.data.text());
                //     if (result.code !== '0') {
                //         throw result.message;
                //     }
                //     resolve(null);
                // } else {
                //     downloadFromStream(response);
                //     throw 'Upload File Error';
                // }
            } catch (error) {
                reject(error);
            }
        };
        const handleDownloadTemplate = async () => {
            const data = await downloadTemplate();
            downloadFromStream(data);
        };

        const handlerYearChange = (value: any) => {
            getVersionByYear({ year: value }).then((res: any) => {
                versionList.value = res;
            });
        };

        const handleReset = () => {
            year.value = undefined;
            version.value = undefined;
        };
        const handleSearch = () => {
            const params = {
                year: year.value,
                version: version.value,
            };
            getListByVersionAndYear(params).then((res: any) => {
                data.value = res.data;
            });
        };

        const init = () => {
            getAllYears().then((res: any) => {
                yearList.value = res;
            });
        };

        onMounted(() => {
            init();
        });

        return {
            uploadYear,
            yearList,
            year,
            versionList,
            version,
            columns,
            data,
            showUploadDrawer,
            handleReset,
            handleSearch,
            handlerYearChange,
            handleUpload,
            handleDownloadTemplate,
        };
    },
});
